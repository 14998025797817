/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import DownArrow from "assets/svgs/DownArrow";
import styled from "styled-components";
// import TopRightArrow from "assets/svgs/TopRightArrow";
// import { useRouter } from "next/router";
import Link from "next/link";
import { useEffect, useState } from "react";
import cityData from "cms/city-data";
import { JobCategory } from "types/job";
import { getUrlPath, slugifyDepartment } from "utils/helpers";
import source from "utils/source";
import { CompanyDataType } from "types/job/CompanyDataType";
import { getUserId, isCanvasMicroSite, isUserLoggedIn } from "utils";
import {
	ArrowForwardOutlined,
	ChevronRight,
	CloseRounded,
	PlayCircleOutline,
} from "@material-ui/icons";
import useJobFeedStore from "components/RevampFeedDetails/JobFeed/store";
import ResumeBuilder from "assets/svgs/ResumeBuilder";
import ResumeChecker from "assets/svgs/ResumeChecker";
import CoverLetter from "assets/svgs/CoverLetter";
import ResumeAi from "assets/svgs/ResumeAi";
import NewBlogIcon from "assets/svgs/NewBlogIcon";
import { Button, IconButton, Modal } from "@material-ui/core";
import useSafePush from "hooks/useSafePush";
import { useRouter } from "next/router";
import { useCandidateProgress } from "modules/candidate/profile/hooks/useCandidateProgress";
import { useCandidateProfile } from "modules/candidate/profile/hooks/useCandidateProfile";
import { useCandidateDetails } from "modules/candidate/profile/hooks/useCandidateDetails";
import { SECTION_MAP } from "modules/candidate/profile/constants/sectionConstants";
import CommunitiesIcon from "assets/svgs/CommunitiesIcon";
import XclusivesIcon from "assets/svgs/XclusivesIcon";
import clsx from "clsx";
import config from "../../../config";
import {
	CategoryNameType,
	CompanyNameType,
	JobCategoryOptionsType,
	JobsListContainerType,
} from "../types";
import Mixpanel from "../../../utils/Mixpanel";

const CAREER_COMPASS = "career_compass";
const COMMUNITY = "community";

const MenuItems = ({ isDark = true }) => {
	const router = useRouter();
	// const navigate = useNavigate();
	const [jobTypeSelection, setJobTypeSelection] =
		useState<JobCategoryOptionsType>("Jobs By City");
	// const employerLoginUrl = `${config.dashboardUrl}/login?utm_source=${
	// 	router?.query?.utm_source || "Header Menu"
	// }&utm_medium=${
	// 	router?.query?.utm_medium || "Candidate%20Hire%20Now%20Button"
	// }&utm_campaign=${router?.query?.utm_campaign || "Header Menu"}&employer=true`;
	const [cityList, setCityList] = useState([]);
	const [companyList, setCompanyList] = useState([]);
	const [departmentList, setDepartmentList] = useState([]);
	const [openVideoModal, setOpenVideoModal] = useState(false);
	const [modalType, setModalType] = useState<string>(COMMUNITY);
	const { safePush } = useSafePush();
	const [isDropdownOpen, setIsDropdownOpen] = useState(true);
	const { data: candidateOnboardingDetails } = useCandidateProgress();
	const { data: candidateDetails } = useCandidateProfile();
	const { data: profileDetails } = useCandidateDetails(candidateDetails?.phoneNumber);
	const isFresher = !profileDetails?.[SECTION_MAP.PROFILE_EDITOR]?.isExperienced;
	const [disableHover, setDisableHover] = useState(false);

	// Function to close the dropdown
	const closeDropdown = () => {
		if (getUrlPath() === "/") {
			setIsDropdownOpen(false);
		}
	};

	const [jobCategory, setJobCategory] = useState<JobCategoryOptionsType>();
	const jobCategories = [
		"Jobs By City",
		"Jobs By Department",
		"Jobs By Company",
		"Jobs By Qualification",
		"Others",
	];
	const { resetSearch, resetFilters, setUrlPath } = useJobFeedStore();
	const handleFindJobTypeHover = (category) => {
		setJobCategory(category);
		setJobTypeSelection(category);
	};
	// const handleHireCandidatesClick = () => {
	// 	import("../../../utils/Mixpanel").then((Mixpanel) => {
	// 		Mixpanel.default.track("Header Hire Candidates Click", {
	// 			Source: source,
	// 			page: getUrlPath(),
	// 			Section: "Header Menu",
	// 		});
	// 	});
	// 	window.open(employerLoginUrl, "_blank");
	// };
	const trackViewAllClick = (viewAllType) => {
		import("../../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track(`Header ${viewAllType} Click`, {
				Source: source,
				page: getUrlPath(),
				Section: "Header Menu",
			});
		});
	};
	const trackJobDropDownSelection = (categorySelection) => {
		import("../../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track(`Header ${categorySelection} Click`, {
				Source: source,
				page: getUrlPath(),
				Section: "Header Menu",
			});
		});
	};

	// const handlePostJobClick = () => {
	// 	import("../../../utils/Mixpanel").then((Mixpanel) => {
	// 		Mixpanel.default.track("Employer login click", {
	// 			page: getUrlPath(),
	// 			Source: source,
	// 			section: "Header",
	// 			actionType: "Redirection",
	// 			cta: "For employers",
	// 		});
	// 	});
	// 	window.open(getEmployerLoginURL(), "_blank");
	// };
	useEffect(() => {
		if (jobTypeSelection === "Jobs By City") {
			import("cms/city-data").then((data: any) => {
				setCityList(data.default?.slice(0, 16) as any);
			});
		} else if (jobTypeSelection === "Jobs By Company") {
			import("cms/company-data").then((data: any) => {
				setCompanyList(data.default?.slice(0, 16) as any);
			});
		} else if (jobTypeSelection === "Jobs By Department") {
			import("cms/department-data").then((data: any) => {
				setDepartmentList(data.default as any);
			});
		}
	}, [jobTypeSelection]);

	const comingSoonBadge = () => (
		<ComingSoonBadge>
			<div className="text-[#9B0000]">Coming soon</div>
		</ComingSoonBadge>
	);

	const menuItems = [
		{
			icon: <ResumeBuilder />,
			header: "AI Resume builder",
			subHeader: "Create your best resume using AI",
			link: `${config.websiteUrl}resume-builder`,
		},
		{
			icon: <ResumeChecker />,
			header: "AI Resume checker",
			subHeader: "Get instant resume feedback",
			link: `${config.websiteUrl}resume-checker`,
		},
		{
			icon: <CoverLetter />,
			header: "AI Cover letter generator",
			subHeader: "Stand out and get hired faster",
			link: `${config.websiteUrl}cover-letter`,
		},
		{
			icon: <ResumeAi />,
			header: "Al Interview",
			subHeader: "Your strategy to success",
			link: `${config.websiteUrl}ai-interview`,
			badge: comingSoonBadge(),
		},
		{
			icon: <NewBlogIcon />,
			header: "Blog",
			subHeader: "Guidance for securing your dream job",
			link: `${config.websiteUrl}career-central/`,
		},
	];

	const communityMenuItems = [
		{
			icon: <CommunitiesIcon />,
			header: "Communities",
			subHeader: "Connect, share, and grow with other professionals",
			link: `${router.basePath}/community`,
		},
		{
			icon: <XclusivesIcon />,
			header: "Xclusives",
			subHeader: "Events & live sessions with industry experts",
			link: `${router.basePath}/xclusives`,
		},
	];

	const renderMenuItem = (menu: (typeof menuItems)[0], removeMargin = false) => (
		<MenuItemRoot
			onClick={() => {
				if (menu.link && menu.header !== "Al Interview") {
					if (menu.header === "Blog") {
						window.open(menu.link, "_blank");
					} else {
						window.location.href = menu.link;
					}
				}
				import("../../../utils/Mixpanel").then((Mixpanel) => {
					Mixpanel.default.track(`Header ${menu.header} Click`, {
						Source: source,
						page: getUrlPath(),
						Section: "Header Menu",
					});
				});
			}}
			removeMargin={removeMargin}
		>
			{menu.icon}
			<FlexColumn>
				<Flex style={{ gap: "8px", alignItems: "center" }}>
					<h5>{menu.header}</h5>
					{menu.badge}
				</Flex>
				<div className="text-[#8C8594]">{menu.subHeader}</div>
			</FlexColumn>
		</MenuItemRoot>
	);
	const renderLeftSection = () => (
		<FlexColumn style={{ gap: "0px" }}>
			{menuItems.map((item) => renderMenuItem(item))}
		</FlexColumn>
	);
	const renderRightSection = () => (
		<RightSectionParent>
			<VideoHolder
				onClick={() => {
					setOpenVideoModal(true);
					setModalType(CAREER_COMPASS);
					setDisableHover(true);
				}}
			>
				<PlayCircleOutline style={{ color: "#fff" }} />
			</VideoHolder>
			<div
				style={{
					width: "226px",
					color: "#172B4D",
					fontSize: "16px",
					fontWeight: 400,
				}}
			>
				Level up your resume: Watch our career compass video guide.
			</div>
			<Button
				endIcon={<ArrowForwardOutlined />}
				style={{
					color: "#1F8268",
					justifyContent: "left",
					fontSize: "14px",
					padding: 0,
					textTransform: "unset",
					background: "none !important",
				}}
				onClick={() => {
					setOpenVideoModal(true);
					setModalType(CAREER_COMPASS);
					setDisableHover(true);
				}}
			>
				Watch video
			</Button>
		</RightSectionParent>
	);

	const renderCommunityRightSection = () => (
		<RightSectionParent>
			<VideoHolderCommunity
				onClick={() => {
					setOpenVideoModal(true);
					setModalType(COMMUNITY);
					setDisableHover(true);
				}}
			>
				<PlayCircleOutline style={{ color: "#fff" }} />
			</VideoHolderCommunity>
			<div
				style={{
					width: "226px",
					color: "#172B4D",
					fontSize: "16px",
					fontWeight: 400,
				}}
			>
				Get started with the Apna Community: watch video to know more
			</div>
			<Button
				endIcon={<ArrowForwardOutlined />}
				style={{
					color: "#1F8268",
					justifyContent: "left",
					fontSize: "14px",
					padding: 0,
					textTransform: "unset",
					background: "none !important",
				}}
				onClick={() => {
					setOpenVideoModal(true);
					setModalType(COMMUNITY);
					setDisableHover(true);
				}}
			>
				Watch video
			</Button>
		</RightSectionParent>
	);

	const getModalVideoUrl = () => {
		if (modalType === CAREER_COMPASS)
			return "https://play.gumlet.io/embed/65f357ef0abc2442f8add909";
		return "https://play.gumlet.io/embed/66684b71bd2ba58f811ea8cc";
	};

	const renderVideoModal = () => (
		<Modal
			open={openVideoModal}
			onClose={() => {
				setOpenVideoModal(false);
				setDisableHover(false);
			}}
		>
			<ModalHolder>
				<iframe
					width="619"
					height="348"
					style={{ marginRight: "auto", marginLeft: "auto" }}
					src={getModalVideoUrl()}
					title="Introduction to apnaAI"
					frameBorder="0"
					allow="autoplay"
				/>
				<IconButton
					onClick={() => {
						setOpenVideoModal(false);
						setDisableHover(false);
					}}
					style={{ position: "absolute", top: 0, right: 0 }}
				>
					<CloseRounded style={{ color: "#fff" }} />
				</IconButton>
			</ModalHolder>
		</Modal>
	);

	const handleClickInsideModal = () => {
		setDisableHover(true);
	};

	return (
		<div className="mx-[28px] flex items-center">
			{isUserLoggedIn() &&
				candidateOnboardingDetails?.isCandidateOnboardingCompleted &&
				!isCanvasMicroSite(router.pathname) &&
				isFresher && (
					<div
						className="link-title cursor-pointer text-[14px] font-semibold leading-[20px] hover:text-[#1F8268]"
						onClick={() => {
							window.open("/home", "_self");
						}}
					>
						Home
					</div>
				)}
			{!isCanvasMicroSite(router.pathname) ? (
				<>
					{isDropdownOpen && (
						<MenuItemContainer>
							<div className="item-container">
								<div
									className="link-title"
									onClick={() => {
										if (
											isUserLoggedIn() &&
											candidateOnboardingDetails?.isCandidateOnboardingCompleted
										) {
											window.open("/jobs", "_self");
										} else {
											window.open("/", "_self");
										}
									}}
								>
									Jobs
								</div>
								<DownArrow fill={isDark ? "#fff" : "#172b4d"} />
								<div className="modal-2">
									<FindJobsContainer>
										<div className="grid grid-cols-2 gap-0 rounded-2xl">
											<JobCategoryContainer
												className="border-r"
												onMouseOver={() => {
													setJobCategory("");
													setIsDropdownOpen(true);
												}}
											>
												{isUserLoggedIn() &&
													candidateOnboardingDetails?.isCandidateOnboardingCompleted &&
													!isCanvasMicroSite(
														router.pathname
													) && (
														<a title="Jobs For You">
															<p
																onClick={() => {
																	trackJobDropDownSelection(
																		"Jobs For You"
																	);
																	resetSearch();
																	resetFilters();
																	window.open(
																		`/jobs`,
																		"_self"
																	);
																}}
															>
																Jobs For You
															</p>
														</a>
													)}
												<Link
													href="/jobs/work_from_home-jobs"
													passHref
												>
													<a title="Work From Home Jobs">
														<p
															onClick={() => {
																trackJobDropDownSelection(
																	"Work From Home Jobs"
																);
																resetSearch();
																resetFilters();
																closeDropdown();
																setUrlPath({
																	work_mode: "wfh",
																});
															}}
														>
															Work From Home Jobs
														</p>
													</a>
												</Link>
												<Link
													href="/jobs/part_time-jobs"
													passHref
												>
													<a title="Part Time Jobs">
														<p
															onClick={() => {
																trackJobDropDownSelection(
																	"Part Time Jobs"
																);
																resetSearch();
																resetFilters();
																closeDropdown();
																setUrlPath({
																	work_type:
																		"part_time",
																});
															}}
														>
															Part Time Jobs
														</p>
													</a>
												</Link>
												<Link href="/jobs/freshers-jobs" passHref>
													<a title="Freshers Jobs">
														<p
															onClick={() => {
																trackJobDropDownSelection(
																	"Freshers Jobs"
																);
																resetSearch();
																resetFilters();
																closeDropdown();
																setUrlPath({
																	min_experience: "0",
																});
															}}
														>
															Freshers Jobs
														</p>
													</a>
												</Link>
												<Link href="/jobs/female-jobs" passHref>
													<a title="Women Jobs">
														<p
															onClick={() => {
																trackJobDropDownSelection(
																	"Women Jobs"
																);
																resetSearch();
																resetFilters();
																closeDropdown();
																setUrlPath({
																	gender: "gender-f",
																});
															}}
														>
															Jobs for women
														</p>
													</a>
												</Link>
												<Link
													href="/jobs/full_time-jobs"
													passHref
												>
													<a title="Full Time Jobs">
														<p
															onClick={() => {
																trackJobDropDownSelection(
																	"Full Time Jobs"
																);
																resetSearch();
																resetFilters();
																closeDropdown();
																setUrlPath({
																	work_type:
																		"full_time",
																});
															}}
														>
															Full Time Jobs
														</p>
													</a>
												</Link>
												<Link
													href="/jobs/night_shift-jobs"
													passHref
												>
													<a title="Night Shift Jobs">
														<p
															onClick={() => {
																trackJobDropDownSelection(
																	"Night Shift Jobs"
																);
																resetSearch();
																resetFilters();
																closeDropdown();
																setUrlPath({
																	work_shift:
																		"night_shift",
																});
															}}
														>
															Night Shift Jobs
														</p>
													</a>
												</Link>
												<Link
													href={`/international/jobs${
														isUserLoggedIn()
															? `?c_id=${isUserLoggedIn()}`
															: ""
													}`}
													passHref
												>
													<a title="International Jobs">
														<p
															onClick={() => {
																trackJobDropDownSelection(
																	"International Jobs"
																);
																closeDropdown();
																resetSearch();
															}}
														>
															International Jobs
														</p>
													</a>
												</Link>
											</JobCategoryContainer>

											{/* Right categories */}
											<JobCategoryContainer>
												{jobCategories.map((category) =>
													category === jobCategory ? (
														<JobCategorySelected
															key={jobCategory}
														>
															{jobCategory}
															<ChevronRight
																fontSize="small"
																className="absolute right-0 top-[8px] !h-[20px] !w-[20px]"
															/>
														</JobCategorySelected>
													) : (
														<JobCategoryAll
															key={category}
															onMouseOver={() =>
																handleFindJobTypeHover(
																	category
																)
															}
														>
															{category}
															<ChevronRight
																fontSize="small"
																className="absolute right-0 top-[8px] !h-[20px] !w-[20px]"
															/>
														</JobCategoryAll>
													)
												)}
											</JobCategoryContainer>
										</div>
										{jobCategory && (
											<JobsContainer>
												{jobCategory === "Jobs By City" && (
													<JobsListContainer col="col-2">
														{(
															cityList as unknown as JobCategory[]
														).map((city) => (
															<Link
																key={city.id}
																href={`/jobs/jobs-in-${city.slug}`}
																passHref
															>
																<a
																	title={`Jobs in ${city.name}`}
																>
																	<p
																		onClick={() => {
																			trackJobDropDownSelection(
																				`Jobs in ${city.name}`
																			);
																			resetSearch();
																			resetFilters();
																			closeDropdown();
																			setUrlPath({
																				city_id:
																					city?.id,
																			});
																		}}
																	>
																		Jobs in{" "}
																		{city.name}
																	</p>
																</a>
															</Link>
														))}
													</JobsListContainer>
												)}
												{jobCategory === "Jobs By Department" && (
													<JobsListContainer col="col-2">
														{(
															departmentList as unknown as JobCategory[]
														)
															.slice(0, 16)
															.map((department) => (
																<div className="name-container">
																	<Link
																		key={
																			department.id
																		}
																		href={`/jobs/dep_${slugifyDepartment(
																			department?.name
																		)}-jobs`}
																		passHref
																	>
																		<a
																			title={`${department.name} Jobs`}
																		>
																			<CategoryName
																				onClick={() => {
																					trackJobDropDownSelection(
																						`${department.name} Jobs`
																					);
																					resetSearch();
																					resetFilters();
																					closeDropdown();
																					setUrlPath(
																						{
																							department_id:
																								department?.id,
																						}
																					);
																				}}
																				className="name"
																				categoryName={
																					department.name
																				}
																			>
																				{department
																					.name
																					.length >
																				18
																					? `${department.name.substring(
																							0,
																							18
																					  )}...`
																					: department.name}
																			</CategoryName>
																		</a>
																	</Link>
																	{department.name
																		.length > 18 && (
																		<div className="tooltip-container">
																			<p className="tooltip-text">
																				{
																					department.name
																				}
																			</p>
																		</div>
																	)}
																</div>
															))}
													</JobsListContainer>
												)}
												{jobCategory === "Jobs By Company" && (
													<JobsListContainer col="col-2">
														{/* TODO: [GP-1032] Fix type of company data here company is not of category type and fix all eslint errors */}
														{(
															companyList as unknown as CompanyDataType[]
														)
															.slice(0, 16)
															.map((company) => (
																<div className="name-container">
																	<Link
																		key={company.name}
																		href={`/jobs/${company.slug}-jobs`}
																		passHref
																	>
																		<a
																			title={`Jobs in ${company.name}`}
																		>
																			<CompanyName
																				onClick={() => {
																					trackJobDropDownSelection(
																						`${company.name} Jobs`
																					);
																					resetSearch();
																					resetFilters();
																					closeDropdown();
																					setUrlPath(
																						{
																							company_id:
																								company?.id,
																						}
																					);
																				}}
																				className="name"
																				companyName={
																					company.name
																				}
																			>
																				{company
																					.name
																					.length >
																				18
																					? `${company.name.substring(
																							0,
																							18
																					  )}...`
																					: company.name}
																			</CompanyName>
																		</a>
																	</Link>
																	{company.name.length >
																		18 && (
																		<div className="tooltip-container">
																			<p className="tooltip-text">
																				{
																					company.name
																				}
																			</p>
																		</div>
																	)}
																</div>
															))}
													</JobsListContainer>
												)}
												{jobCategory ===
													"Jobs By Qualification" && (
													<JobsListContainer col="col-1">
														<Link
															href="/jobs/10th_pass-jobs"
															passHref
														>
															<a title="10th Pass Jobs">
																<p
																	onClick={() => {
																		trackJobDropDownSelection(
																			"10th Pass Jobs"
																		);
																		resetSearch();
																		resetFilters();
																		closeDropdown();
																		setUrlPath({
																			education_level: 1,
																		});
																	}}
																>
																	10th Pass Jobs
																</p>
															</a>
														</Link>
														<Link
															href="/jobs/12th_pass-jobs"
															passHref
														>
															<a title="12th Pass Jobs">
																<p
																	onClick={() => {
																		trackJobDropDownSelection(
																			"12th Pass Jobs"
																		);
																		resetSearch();
																		resetFilters();
																		closeDropdown();

																		setUrlPath({
																			education_level: 2,
																		});
																	}}
																>
																	12th Pass Jobs
																</p>
															</a>
														</Link>
														<Link
															href="/jobs/diploma-jobs"
															passHref
														>
															<a title="Diploma Jobs">
																<p
																	onClick={() => {
																		trackJobDropDownSelection(
																			"Diploma Jobs"
																		);
																		resetSearch();
																		resetFilters();
																		closeDropdown();

																		setUrlPath({
																			education_level: 3,
																		});
																	}}
																>
																	Diploma Jobs
																</p>
															</a>
														</Link>
														<Link
															href="/jobs/iti-jobs"
															passHref
														>
															<a title="ITI Jobs">
																<p
																	onClick={() => {
																		trackJobDropDownSelection(
																			"ITI Jobs"
																		);
																		resetSearch();
																		resetFilters();
																		closeDropdown();

																		setUrlPath({
																			education_level: 4,
																		});
																	}}
																>
																	ITI Jobs
																</p>
															</a>
														</Link>
														<Link
															href="/jobs/graduate-jobs"
															passHref
														>
															<a title="Graduate Jobs">
																<p
																	onClick={() => {
																		trackJobDropDownSelection(
																			"Graduate Jobs"
																		);
																		resetSearch();
																		resetFilters();
																		closeDropdown();

																		setUrlPath({
																			education_level: 5,
																		});
																	}}
																>
																	Graduate Jobs
																</p>
															</a>
														</Link>
														<Link
															href="/jobs/post_graduate-jobs"
															passHref
														>
															<a title="Post Graduate Jobs">
																<p
																	onClick={() => {
																		trackJobDropDownSelection(
																			"Post Graduate Jobs"
																		);
																		resetSearch();
																		closeDropdown();

																		resetFilters();
																		setUrlPath({
																			education_level: 6,
																		});
																	}}
																>
																	Post Graduate Jobs
																</p>
															</a>
														</Link>
													</JobsListContainer>
												)}
												{jobCategory === "Others" && (
													<JobsListContainer col="col-1">
														<Link
															href="/free-job-alerts"
															passHref
														>
															<a title="Free job Alert">
																<p
																	onClick={() =>
																		trackJobDropDownSelection(
																			"Free Job Alert"
																		)
																	}
																>
																	Free Job Alert
																</p>
															</a>
														</Link>
														<Link
															href="/download-apna-app"
															passHref
														>
															<a title="Download Apna App">
																<p
																	onClick={() =>
																		trackJobDropDownSelection(
																			"Download Apna App"
																		)
																	}
																>
																	Download Apna app
																</p>
															</a>
														</Link>
														<Link
															href="https://apna.co/career-central/"
															passHref
														>
															<a title="Blog">
																<p
																	onClick={() =>
																		trackJobDropDownSelection(
																			"Blog"
																		)
																	}
																>
																	Blog
																</p>
															</a>
														</Link>
														<Link href="/contact-us" passHref>
															<a title="Contact">
																<p
																	onClick={() =>
																		trackJobDropDownSelection(
																			"Contact"
																		)
																	}
																>
																	Contact Us
																</p>
															</a>
														</Link>
													</JobsListContainer>
												)}
												<ViewAllContainer>
													{jobCategory === "Jobs By City" && (
														<Link
															href={{
																pathname: "/job-search",
																query: {
																	keyword:
																		jobTypeSelection,
																},
															}}
															passHref
														>
															<a
																title={`View All ${cityData.length} Cities`}
															>
																<ViewAllLabel
																	onClick={() =>
																		trackViewAllClick(
																			"View All Cities"
																		)
																	}
																>{`View All ${cityData.length} Cities`}</ViewAllLabel>
															</a>
														</Link>
													)}
													{jobCategory ===
														"Jobs By Department" && (
														<Link
															href={{
																pathname: "/job-search",
																query: {
																	keyword:
																		jobTypeSelection,
																},
															}}
															passHref
														>
															<a
																title={`View All ${departmentList.length} Departments`}
															>
																<ViewAllLabel
																	onClick={() =>
																		trackViewAllClick(
																			"View All Categories"
																		)
																	}
																>{`View All ${departmentList.length} Departments`}</ViewAllLabel>
															</a>
														</Link>
													)}
													{jobCategory ===
														"Jobs By Company" && (
														<Link
															href={{
																pathname: "/job-search",
																query: {
																	keyword:
																		jobTypeSelection,
																},
															}}
															passHref
														>
															<a title="View All Companies">
																<ViewAllLabel
																	onClick={() =>
																		trackViewAllClick(
																			"View All Companies"
																		)
																	}
																>
																	View All Companies
																</ViewAllLabel>
															</a>
														</Link>
													)}
												</ViewAllContainer>
											</JobsContainer>
										)}
									</FindJobsContainer>
								</div>
							</div>
						</MenuItemContainer>
					)}
					<MenuItemContainer className={disableHover ? "no-hover" : ""}>
						<div className="item-container">
							<div
								className="link-title text-nowrap"
								onClick={() => {
									window.open("/resume-builder", "_self");
								}}
							>
								Career Compass
							</div>
							<DownArrow fill={isDark ? "#fff" : "#172b4d"} />
							<div className={`modal-2 ${disableHover ? "no-hover" : ""}`}>
								<div className="rounded-[16px] border-[1px] border-solid border-[#E8E7EA] bg-white p-[20px] shadow-md">
									<div className="flex">
										<div
											style={{
												width: "352px",
												borderRight: "1px solid #DFE1E6",
												marginRight: "20px",
											}}
										>
											{renderLeftSection()}
										</div>
										<div>{renderRightSection()}</div>
									</div>
								</div>
							</div>
						</div>
					</MenuItemContainer>
					<MenuItemContainer>
						<div className="item-container">
							<div
								className="link-title"
								onClick={() => {
									trackJobDropDownSelection("community");
									window.open(`${router.basePath}/community`, "_self");
								}}
							>
								Community
							</div>
							<DownArrow fill={isDark ? "#fff" : "#172b4d"} />
							<div
								className={`modal-2 community-modal ${
									disableHover ? "no-hover" : ""
								}`}
							>
								<div className="rounded-[16px] border-[1px] border-solid border-[#E8E7EA] bg-white p-[20px] shadow-md">
									<div className="flex">
										<div
											className="flex flex-col"
											style={{
												width: "352px",
												borderRight: "1px solid #DFE1E6",
												paddingRight: "20px",
												marginRight: "20px",
											}}
										>
											{communityMenuItems.map((item) =>
												renderMenuItem(item, true)
											)}
										</div>
										<div> {renderCommunityRightSection()} </div>{" "}
									</div>
								</div>
							</div>
						</div>
					</MenuItemContainer>
					<MenuItemContainer
						className={clsx(
							"link-title text-[14px] !font-[600] leading-[20px] !text-[#190A28]",
							{
								"!text-[#1F8268]": router.pathname === "/learn/degree",
							}
						)}
						onClick={() => {
							Mixpanel.track("Learn now button Clicked", {
								name: profileDetails?.[SECTION_MAP.OVERALL]
									?.profileCardDetails?.name,
								UserPlatform: "web",
								web_logged_in: isUserLoggedIn() ? "yes" : "no",
								device: "Mobile",
								UserUID: getUserId(),
								phone: candidateDetails?.phoneNumber,
							});
							router.push("/learn/degree?utm_source=Web_apna");
						}}
					>
						Learn
					</MenuItemContainer>
				</>
			) : null}

			{openVideoModal && renderVideoModal()}
		</div>
	);
};
export default MenuItems;

const MenuItemContainer = styled.div`
	margin-left: 24px;
	color: #fff;
	font-weight: bold;
	position: relative;
	&:first-child {
		margin-left: 36px;
	}

	a {
		text-decoration: none;
	}

	.link-title {
		font-size: 14px;
		margin: 0;
		color: #190a28;
		font-weight: 600;
		line-height: 20px;
	}
	&:hover {
		cursor: pointer;
	}
	.item-container {
		display: flex;
		justify-content: center;
		align-items: center;
		&:hover svg {
			fill: #1f8268;
		}
		&:hover .link-title {
			color: #1f8268;
		}
	}
	.modal-1 {
		position: absolute;
		z-index: 10000;
		top: 140%;
		left: -75%;
		width: 226px;
		height: 284px;
		background-color: #fff;
		border-radius: 8px;
		display: none;
		transition: 0.5s ease;
		padding: 30px;
		box-shadow: 0px 4px 8px rgba(96, 97, 112, 16%), 0px 0px 2px rgba(40, 41, 61, 4%);

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: -12.5px;
			left: 0;
			width: 100%;
			height: 35px;
			background-color: transparent;
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: -12px;
			left: 70%;
			z-index: 10001;
			width: 0;
			height: 0;
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-bottom: 12px solid #fff;
			border-top-radius: 3px;
		}
		p {
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 12px;
			color: #6b7c8f;
			font-weight: 400;
		}
		p:hover {
			color: #31445a;
			font-weight: 500;
		}
	}

	.modal-2 {
		position: absolute;
		z-index: 10000;
		top: 150%;
		left: 0;
		min-width: 470px;
		// background-color: #fff;
		border-radius: 12px;
		display: none;
		// box-shadow: 0px 4px 8px rgba(96, 97, 112, 16%), 0px 0px 2px rgba(40, 41, 61, 4%);
		transition: 0.5s ease;
		// min-height: 284px;

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: -20.5px;
			left: 0;
			width: 100%;
			height: 35px;
			background-color: transparent;
		}

		// &:after {
		// 	content: "";
		// 	display: block;
		// 	position: absolute;
		// 	top: -12px;

		// 	z-index: 1;
		// 	width: 0;
		// 	height: 0;
		// 	border-left: 12px solid transparent;
		// 	border-right: 12px solid transparent;
		// 	border-bottom: 12px solid #fff;
		// 	border-top-radius: 3px;
		// }
	}
	.community-modal {
		min-width: 426px !important;
	}
	.item-container:hover .modal-1 {
		display: block;
	}
	.item-container:hover .modal-2 {
		display: block;
	}
	.item-container .modal-1.no-hover,
	.item-container .modal-2.no-hover {
		display: none;
	}
`;

const FindJobsContainer = styled.div`
	box-shadow: 0px 4px 8px rgba(96, 97, 112, 16%), 0px 0px 2px rgba(40, 41, 61, 4%);
	border-radius: 16px;
	background: #fff;
`;

const JobCategoryContainer = styled.div`
	// border-right: 1px solid #e6e6e6;
	// min-height: 276px;

	padding: 15px 16px 15px 0;

	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		padding: 6px 20px;
		margin-bottom: 0;
		color: #8c8594;
		display: inline-block;
		width: 100%;

		&:hover {
			// background: #eaedf2;
			font-weight: 600;
			color: #1f8268;
		}
	}
`;
const JobsContainer = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: #fff;
	border-radius: 16px;

	position: absolute;
	top: 0;
	left: 103%;

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: -20.5px;
		left: -18px;
		right: 100%;
		bottom: 0;
		background-color: transparent;
	}

	box-shadow: 0px 4px 8px rgba(96, 97, 112, 16%), 0px 0px 2px rgba(40, 41, 61, 4%);
`;

const JobsListContainer = styled.div<JobsListContainerType>`
	display: ${({ col }) => (col === "col-2" ? "grid" : "block")};
	grid-template-columns: ${({ col }) => (col === "col-2" ? "1fr 1fr" : "1fr")};
	row-gap: 8px;
	column-gap: 60px;

	p {
		width: 156px;
		display: block;
		margin-bottom: ${({ col }) => (col === "col-2" ? "0px" : "8px")};
		white-space: nowrap;
		color: #6b7c8f;
		font-weight: 400;
		font-size: 16px;
	}
	p:hover {
		font-weight: 600;
		color: #1f8268;
	}
	a {
		text-decoration: none;
	}

	.name-container {
		position: relative;
	}
	.tooltip-container {
		display: none;
		position: absolute;
		z-index: 1;
		top: -140%;
		left: 0%;
		font-size: 12px;
		background-color: #000;
		white-space: nowrap;
		min-width: 50px;
		border-radius: 10px;
		padding: 5px 10px;
	}
	.tooltip-container ::after {
		content: "";
		display: block;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid #000;
		border-top-radius: 3px;
	}
	.name-container:hover .tooltip-container {
		display: block;
	}

	.tooltip-text {
		font-size: 14px;
		color: #fff;
		text-align: center;
		text-overflow: initial;
		white-space: nowrap;
		width: initial;
	}
	.tooltip-text:hover {
		color: #fff;
		font-weight: 400;
		cursor: initial;
	}
`;

const CompanyName = styled.p<CompanyNameType>``;
const CategoryName = styled.p<CategoryNameType>``;

const ViewAllContainer = styled.div`
	text-align: left;
	a {
		text-decoration: none;
	}
	p {
		text-align: center;
		font-weight: 400;
	}
	p:hover {
		font-weight: 500;
	}
`;

const JobCategoryAll = styled.p`
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #6b7c8f;
	margin-bottom: 16px;
	padding: 4px 60px 4px 16px;
	position: relative;
`;
const JobCategorySelected = styled.p`
	font-size: 16px;
	color: #31445a;
	font-weight: 500;
	// background-color: #eaedf2;
	line-height: 24px;
	padding: 4px 60px 4px 16px;
	position: relative;
`;

const ViewAllLabel = styled.p`
	color: #1f8268;
	margin-top: 24px;
	font-size: 16px;
	margin-bottom: 0;
	border: 1px solid #1f8268;
	padding: 8px 16px;
	border-radius: 4px;
	font-weight: 600;
	display: inline-block;
`;
const MenuItemRoot = styled.div<MenuItemRootType>`
	gap: 12px;
	color: #172b4d;
	font-size: 16px;
	display: flex;
	line-height: 16px;
	padding: 8px;
	margin-left: -8px;
	margin-right: ${({ removeMargin }) => (removeMargin ? "0px" : "24px")};
	cursor: pointer;
	border-radius: 8px;
	background-color: #fff;
	transition: background-color 150ms;
	align-items: center;

	&:hover {
		background-color: #ebf3fe;
	}

	& h5 {
		font-weight: 600;
		margin-bottom: 0;
		font-size: 14px;
		line-height: 20px;
	}
	& div {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		margin-top: 0;
	}
`;

const ComingSoonBadge = styled.div`
	color: #9b0000 !important;
	border-radius: 16px;
	background: #fbeced;
	padding: 2px 8px;
	margin-bottom: 2px;
	& div {
		font-size: 12px;
		font-weight: 600;
		line-height: 12px;
		margin-top: 2px;
	}
`;
export const Flex = styled.div`
	display: Flex;
`;
export const FlexColumn = styled(Flex)`
	flex-direction: column;
`;
const RightSectionParent = styled(Flex)`
	flex-direction: column;
	gap: 16px;
`;

const VideoHolder = styled.div`
	border-radius: 8px;
	background: url(https://video.gumlet.io/65f357cb0abc2442f8add7ef/65f357ef0abc2442f8add909/thumbnail-1-0.png?v=1710446636148);
	background-size: cover;
	background-position: center;
	display: flex;
	width: 226px;
	height: 121px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	cursor: pointer;
	svg {
		display: none;
	}
`;

const VideoHolderCommunity = styled(VideoHolder)`
	background: url(https://video.gumlet.io/604781cd5a14961dd9f58372/66684b71bd2ba58f811ea8cc/thumbnail-1-0.png?v=1718111163712);
	background-position-x: -4px;
	background-size: 102%;
	svg {
		display: none;
	}
`;

const ModalHolder = styled.div`
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	width: 619px;
	border: none;
	outline: none;
`;

type MenuItemRootType = {
	removeMargin?: boolean;
};
